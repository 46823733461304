<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    " 
    fluid
  >
    <status-bar></status-bar>

    <v-row>
      <v-col cols="12">
        <v-card 
          class="ma-15"
          flat
        >
          <v-card-title class="justify-center">
            <h1 class="font">
              Contact Us
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="firstName"
                label="First Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="Last Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                required
              ></v-text-field>
              <v-textarea
                v-model="message"
                label="Message"
                @click:append="showPassword = !showPassword"
                required
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-row>
            <v-col>
              <v-card-actions class="text-center">
                <v-row>
                  <v-col>
                    <v-btn color="#97C657" dark>Submit</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <Footer></Footer>

  </v-container>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

import { mapState } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      email: '',
      message: '',
      firstName: '',
      lastName: '',
    }
  },
  components: {
    StatusBar,
    Footer,
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    loggedIn() {
      this.$store.dispatch('loggedIn')
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.$store.dispatch('loggedIn')
  },
}
</script>

<style scoped>
.font {
  font-family: "NunitoSans-Light";
  color: #77787B;
}
</style>
